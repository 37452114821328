import React, { useEffect } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  FaAward,
  FaChartBar,
  FaUserGraduate,
  FaWrench,
  FaProjectDiagram,
  FaBuilding,
  FaBriefcase,
  FaGraduationCap,
} from "react-icons/fa";

import Layout from "../../components/layout";
import NewsLetter from "../../components/news-letter/news-letter.component";
import Seo from "../../components/seo";
import animations from "../../lib/animations.lib";
import { respH } from "../../lib/helpers";

import "./index.styles.scss";

// markup
const ServicesPage = () => {
  useEffect(() => {
    const animate = animations();
    animate.animateFadeIn();
  });

  return (
    <Layout pageClass="servizi">
      <Seo
        title="Servizi"
        description="Corsi per professionisti in ambito BIM e project management. Servizi e consulenza per aziende in ambito formazione. Personalizzazione di piattaforme e-learning."
      />
      <section className="hero header is-large">
        <div className="hero-body">
          <div className="container is-fluid has-text-centered is-italic">
            <h1 className={`title ${respH(2)} has-text-white mb-6`}>
              Uno sforzo continuo è la chiave che sprigiona il nostro
              potenziale.
            </h1>
            <h2 className={`subtitle ${respH(3)} has-text-white`}>
              — Sir Winston Churchil
            </h2>
          </div>
        </div>
      </section>

      <section className="section is-medium has-text-centered">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h1 id="corsi-professionisti" className={`title ${respH(1)}`}>
                <FaAward /> Corsi per professionisti
              </h1>
            </div>
          </div>
          <div className="columns fade-in">
            <div className="column has-padding-mobile">
              <StaticImage
                className="clipped clipped-one"
                quality="85"
                alt="corsi per professionisti image"
                src="../../images/servizi/1.png"
              />
            </div>
            <div className="column is-vcenter-desktop ">
              <div>
                <h2 className={`title ${respH(2)}`}>
                  <FaUserGraduate /> Corsi dedicati alle persone per lo sviluppo
                  professionale
                </h2>
                <p className="block is-size-4 has-text-weight-light">
                  La Fucina dei Talenti propone opportunità formative dedicate
                  alle persone per lo sviluppo professionale.
                </p>
                <p className="block is-size-4 has-text-weight-light">
                  Attualmente offriamo i seguenti percorsi formativi:
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section services business has-text-centered ">
        <div className="container">
          <div className="columns fade-in has-reversed-columns">
            <div className="column is-vcenter-desktop is-size-4 has-text-weight-light">
              <div>
                <h2 className={`title ${respH(2)} `}>
                  <FaWrench /> BIM – Building Infomation Modeling
                </h2>
                <p className="is-size-4 block has-text-weight-light">
                  Le proposte formative sono suddivise in due categorie. Corsi
                  per l'apprendimento e l'approfondimento di software quali
                  Revit, Narviswork, Dynamo, corsi indirizzati all'ottenimento
                  delle certificazioni professionali di Bim Specialist, Bim
                  Coordinator, BIM Manager, CDE Manager.
                </p>
                <Link
                  to="/corsi-eventi"
                  className="block button is-medium is-rounded"
                >
                  Vieni a scoprire i nostri corsi
                </Link>
              </div>
            </div>
            <div className="column has-padding-mobile">
              <StaticImage
                className="clipped clipped-two"
                quality="85"
                alt="BIM image"
                src="../../images/servizi/2.jpg"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section has-text-centered">
        <div className="container">
          <div className="columns fade-in">
            <div className="column has-padding-mobile">
              <StaticImage
                className="clipped clipped-three"
                quality="85"
                alt="corsi per professionisti image"
                src="../../images/servizi/3.jpg"
              />
            </div>
            <div className="column is-vcenter-desktop">
              <div>
                <h2 className={`title ${respH(2)}`}>
                  <FaProjectDiagram /> Project Management
                </h2>
                <p className="is-size-4 block has-text-weight-light">
                  La proposta formativa è volta a portare gli allievi alla
                  dimestichezza delle principali tecniche di project management
                  ed alla certificazione delle competenze.
                </p>
                <Link
                  to="/corsi-eventi"
                  className="button is-medium is-rounded block"
                >
                  Vieni a scoprire i nostri corsi
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section has-text-centered">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h1 id="corsi-aziende" className={`title ${respH(1)}`}>
                <FaBuilding /> Servizi alle aziende
              </h1>
            </div>
          </div>
          <div className="columns fade-in has-reversed-columns">
            <div className="column is-vcenter-desktop">
              <div>
                <h2 className={`title ${respH(2)}`}>
                  <FaChartBar /> Opportunità di formazione e di crescita per le
                  aziende
                </h2>
                <div className="is-size-4 has-text-weight-light">
                  <p className="block">
                    <span className="is-italic">Lifelong learning</span> – la
                    chiave del successo e della crescita aziendale.
                  </p>
                  <p className="block">
                    La formazione è uno degli strumenti più potenti per fornirsi
                    di nuove competenze e per migliorare la produttività
                    aziendale.
                  </p>
                  <p className="block">
                    Fucina dei Talenti si propone come partner per le aziende
                    nell’ambito della gestione della formazione: analisi
                    fabbisogni formativi, ricerca finanziamenti, amministrazione
                    e erogazione corsi.
                  </p>
                  <p className="block">
                    Inoltre, Fucina dei Talenti si propone di fornire alle
                    aziende servizi di contenuto altamente professionale, per
                    facilitare il vostro sviluppo.
                  </p>
                </div>
              </div>
            </div>
            <div className="column has-padding-mobile">
              <StaticImage
                className="clipped clipped-four"
                quality="85"
                alt="corsi per professionisti image"
                src="../../images/servizi/4.jpg"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section is-medium has-text-centered">
        <div className="columns mb-6">
          <div className="column">
            <h3 className={`title ${respH(2)}`}>
              Scopri i servizi consulenziali e di formazione:
            </h3>
            <div className="is-size-4 has-text-weight-light">
              <p className="block">
                <FaBriefcase /> Consulenza in ambito HR, organizzazione
                aziendale
              </p>
              <p className="block">
                <FaGraduationCap /> Creazione di piattaforme personalizzate
                e-learning
              </p>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <p className={`block title respH(3)`}>
              Scrivi o chiama per approfondimenti
            </p>
            <Link to="/contatti" className="block button is-medium is-rounded ">
              Contattaci
            </Link>
          </div>
        </div>
      </section>
      <NewsLetter />
    </Layout>
  );
};

export default ServicesPage;
